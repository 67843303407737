import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Row, Col } from "react-styled-flexboxgrid"
import { Primary, Secondary, Alternate, Parallax } from "../Section"
// import HomeHero from "./HomeHero"
// import SkysTheLimit from "./SkysTheLimit"
import SvgImage from "../SvgImage"
// import { BackgroundSectionStyle } from "../Section/backgroundSection.css.js"
import LogoTjh from "../../images/logo-tjh.svg"
import LogoYingYang from "../../images/logo-ying-yang.svg"
import LogoTouchless from "../../images/logo-touchless.svg"
import LogoFmd from "../../images/logo-fmd.svg"
import ImgOperating from "../../images/operating.jpg"
import ImgMagazine from "../../images/magazine.jpg"
import ImgDelray from "../../images/img-delray-downtown.jpg"
import ImgFlatware from "../../images/img-flatware.jpg"

// const StyledHomeHero = styled(HomeHero)`
//   ${BackgroundSectionStyle};
//   padding-top: 10rem;
//   min-height: ${props => props.backgroundHeight}px;
//   text-align: ${props => (props.centered ? "center" : "initial")};
// `
// const StyledSkysTheLimit = styled(SkysTheLimit)`
//   ${BackgroundSectionStyle};
//   min-height: ${props => props.backgroundHeight}px;
//   text-align: ${props => (props.centered ? "center" : "initial")};
// `
const StyledImgOperating = styled.img`
  display: block;
  margin: 0 auto;
`
const MagazineThumbnail = styled.img`
  height: 195px;
  transition: all 200ms;
  transform: scale(1);
  border: 1px solid transparent;
  &:hover {
    transform: scale(1.1);
    border: 4px solid ${props => props.theme.white};
  }
`

const Home = () => {
  return (
    <section>
      {/* <StyledHomeHero backgroundHeight={550} centered>
        <h1>MICHAEL S. JOYNER, MD</h1>
        <h2>Del Ray Beach & Boca Raton Hand Surgeon</h2>
        <blockquote>
          "THE HUMAN HAND ALLOWS THE MIND TO REVEAL ITSELF" - Maria Montessori
        </blockquote>
      </StyledHomeHero> */}
      <Parallax centered backgroundImage={ImgDelray} background-height={550}>
        <h1>MICHAEL S. JOYNER, MD</h1>
        <h2>Delray Beach & Boca Raton Hand Surgeon</h2>
        <blockquote>
          "THE HUMAN HAND ALLOWS THE MIND TO REVEAL ITSELF" - Maria Montessori
        </blockquote>
      </Parallax>
      <Primary centered>
        <Row>
          <Col xs={12}>
            <SvgImage
              width={350}
              imgSrc={LogoTjh}
              altText="The Joyner Hand Logo"
            />
            <h3>HAND AND MICROSURGERY</h3>
            <p>
              Do you want strong and healthy hands? Do you have carpal tunnel
              syndrome, arthritis, tennis elbow, locking fingers or hand pain
              from an injury? Doctor Joyner specializes in surgical and
              non-surgical hand care at The Joyner Hand, PA in Boca Raton,
              Florida.
            </p>
          </Col>
        </Row>
      </Primary>
      <Secondary centered>
        <Row>
          <Col xs={12} md={6}>
            <SvgImage
              width={350}
              imgSrc={LogoTouchless}
              altText="Logo Touchless flatware"
            />
            <h3>INVENTOR OF TOUCHLESS FLATWARE</h3>
            <p>
              Don’t let your flatware touch tabletop surfaces! Doctor Michael S.
              Joyner is not only a Hand and Microsurgeon in Boca Raton he is
              also the creator and founder of the Touchless Flatware Patented
              Designs.
            </p>
          </Col>

          <Col xs={12} md={6}>
            <SvgImage height={350} imgSrc={LogoFmd} altText="Logo FMD" />
            <h3>FOUNDER OF FMD GRAND</h3>
            <p>
              Doctor Joyner is the president and founder of Forever My Daddy
              Grand Foundation, a 501c3 non-profit dedicated to fathers and
              grandparents who are affected by obstacles such as parental
              alienation that prevent the development of true loving
              relationships with their children and grandchildren.
            </p>
          </Col>
        </Row>
      </Secondary>
      <Alternate centered>
        <Row>
          <Col xs={12}>
            <SvgImage
              height={350}
              imgSrc={LogoYingYang}
              altText="The Joyner Ying Yang Logo"
            />
            <StyledImgOperating src={ImgOperating} alt="Dr. Joyner operating" />
          </Col>
        </Row>
      </Alternate>
      <Parallax centered backgroundImage={ImgFlatware} background-height={550}>
        <h3>
          SEE OUR LATEST MAGAZINE AND FIND OUT ABOUT THE LATEST NEWS AND
          PRODUCTS
        </h3>
        <Link to="/skys-the-limit-magazine">
          <MagazineThumbnail src={ImgMagazine} />
        </Link>
      </Parallax>
      {/* <StyledSkysTheLimit backgroundHeight={500} centered></StyledSkysTheLimit> */}
    </section>
  )
}

export default Home
